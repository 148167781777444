.animate-pulse-gradient {
  background: linear-gradient(to right, #e2e8f0 0%, #ffffff 50%, #e2e8f0 100%);
  background-size: 200% auto;
  animation: pulse-gradient 2s ease-in-out infinite;
}

@keyframes pulse-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.animate-button {
  background: linear-gradient(to right, #16a34a 0%, #22c55e 50%, #16a34a 100%);
  background-size: 200% auto;
  animation: pulse-gradient 3.5s ease-in-out infinite;
}

@keyframes pulse-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.animate-action-button {
  background: linear-gradient(to right, #664bc7 0%, #8b6ff0 50%, #664bc7 100%);
  background-size: 200% auto;
  animation: pulse-gradient 4.5s ease-in-out infinite;
}

@keyframes pulse-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
