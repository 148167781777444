@tailwind base;
@tailwind components;
@tailwind utilities;

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* For Firefox */
html {
  scrollbar-width: none;
}

body {
  scrollbar-width: none;
}
